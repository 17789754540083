@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Satisfy&display=swap');

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #679466 #ffffff;
  cursor: none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #679466;
  border-radius: 10px;
  border: 3px inset #ffffff;
}

:root {
  --primary: #0a2730;
  --secondary: #d8e2ee;
  --static-primary: #0a2730;
  --static-secondary: #d8e2ee;
  --accent: #679466;
  --heading-font: "impact";
  --content-font: "Libre Franklin";
  --cursive-font: 'Satisfy';
}

.dark {
  --primary: #0a2730;
  --secondary: #d8e2ee;
}

.light {
  --primary: #d8e2ee;
  --secondary: #0a2730;
}

::selection {
  color: var(--primary);
  background-color: var(--secondary);
}

@font-face {
  font-family: impact;
  src: url(./fonts/impact.ttf);
}

@layer components {
  .btn-primary {
    @apply capitalize cursor-pointer text-sm border bg-secondary px-2 py-1 rounded-lg text-primary shadow-md duration-200 hover:shadow-black;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  mix-blend-mode: difference;
}

.text-instagram {
  background: rgb(249, 201, 1);
  background: linear-gradient(45deg,
      rgba(249, 201, 1, 1) 3%,
      rgba(246, 9, 16, 1) 34%,
      rgba(128, 62, 177, 1) 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gradient-bg {
  background: var(--accent);
  background: linear-gradient(180deg, var(--accent) 60%, var(--primary) 100%);
}

/* .carousel-container {
  position: relative;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1; 

.prev {
  left: 10px;
}

.next {
  right: 10px;
} */

.alice-carousel {
  width: 100%;
  /* height: 50vh; */
}

/* .alice-carousel__stage{} */
/* .alice-carousel__stage-item{} */
.alice-carousel__prev-btn {
  /* display: none; */
  font-size: 5vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50% !important;
}

/* .alice-carousel__prev-btn-item{} */
.alice-carousel__next-btn {
  /* display: none; */
  font-size: 5vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -50% !important;
}

/* .alice-carousel__next-btn-item{} */
/* .alice-carousel__play-btn{} */
/* .alice-carousel__play-btn-item{} */

.alice-carousel__dots {
  display: none;
}

.homepagecarousel .alice-carousel__dots {
  display: block;
  position: absolute;
  bottom : 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.411);
  padding: 5px;
  width: 100%;
}

.homepagecarousel .alice-carousel__dots-item.__active{
  background: #f3f3f3 !important;
  padding: 5px;
}

.homepagecarousel .alice-carousel__dots-item {
  background: var(--static-primary) !important;
  padding: 5px;
}

/* .alice-carousel__dots-item{} */
/* .alice-carousel__slide-info{} */
/* .alice-carousel__slide-info-item{} */